<template>
  <div id="download">
    <div class="download__qr__box">
      <img class="ios" src="@/assets/img/mobile/ios-download.png" alt="" @click="showPopup">
      <img class="android" src="@/assets/img/mobile/android-download.png" alt="" @click="showPopup">
      <div class="popup" v-if="isPopupVisible" @click.self="hidePopup">
      <p>어플리케이션 준비중</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupVisible: false,
    };
  },
  methods: {
    showPopup() {
      this.isPopupVisible = true; // 이미지 클릭 시 팝업 표시
    },
    hidePopup() {
      this.isPopupVisible = false; // 팝업 바깥 클릭 시 팝업 숨김
    },
  },
};
</script>
